import { EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Image } from "antd";
import React, { useEffect } from "react";
import { IArticle } from "../../../domain/articles/IArticle";
import { AppRoutes, navigateToWithParams } from "../../../domain/navigators/AppRoutes";
import { translate } from "../../../domain/wiring/AppDomain";
import { getArticleImageUrl } from "../../../helpers/imageHelpers";
import { moneyFormat } from "../../../helpers/tsHelper";
import styles from "./ArticleBlock.module.css";

interface IProps {
    article: IArticle;
}

export function ArticleBlock(props: IProps) {

    const { article } = props;

    const navigateToArticle = (e: any) => {
        e.preventDefault();
        return navigateToWithParams(AppRoutes.articleView, article.number);
    };

    return <div className={styles.articleBlock} >
        <div onClick={navigateToArticle} className={styles.articleImageSpace}>
            {
                article.attachmentKeys?.length > 0
                    ? <Image src={getArticleImageUrl(article.rowId, article.attachmentKeys[0])} preview={false} />
                    : <Image src="assets/images/noimage.jpg" preview={false} />
            }
        </div>
        <div className={styles.articleInfo}>
            <div className={styles.articleName} onClick={navigateToArticle}>{article.name}</div>
            <div className={styles.infoGrid}>

                <div className={styles.salesPrice}>
                    <div>{translate("itemNumber")}</div>
                    <div>{article.number}</div>
                </div>
                <div className={styles.salesPrice}>
                    <div>{translate("salesPrice")}</div>
                    <div>{moneyFormat(article.salesPrice, article.salesPriceCurrencyText)}</div>
                </div>
                <div className={styles.articleUnit}>
                    <div>{translate("unit")}</div>
                    <div>{translate(article.unitName.toLocaleLowerCase())}</div>
                </div>
                <div className={styles.blocked}>
                    <div>{translate("blocked")}</div>
                    <div><Checkbox defaultChecked={article.blocked} disabled /></div>
                </div>
            </div>
            <div className={styles.rightBottomSpace}>
                <Button type="primary" style={{ background: "var(--color-media)", borderColor: "var(--color-media)" }} onClick={navigateToArticle} icon={<EditOutlined />}>{translate("edit")}</Button>
            </div>
        </div>
    </div>;
}
