import React, { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { PageWrapper } from "../../components/core/PageWrapper/PageWrapper";
import { navigateToWithParams, AppRoutes } from "../../domain/navigators/AppRoutes";
import { translate } from "../../domain/wiring/AppDomain";
import { HeaderActionBar, IActionButton, IToggleSwitch } from "./headerbar/HeaderActionBar";
import styles from "./HomePageView.module.css";
import { ArticleList } from "./list/ArticleList";

interface IProps { }

export const HomePageView = (props: IProps): JSX.Element => {
    const [useList, setUseList] = useState(() => {
        const useListState = JSON.parse(localStorage.getItem("useListState") || "false");
        return useListState;
    });

    React.useEffect(() => {
        localStorage.setItem("useListState", JSON.stringify(useList));
      }, [useList]);

    const buttons: IActionButton[] = [
        {
            position: "right",
            content: <>{ translate("add") }</>,
            icon: <PlusOutlined />,
            onClick: () => navigateToWithParams(AppRoutes.articleView, "create")
        }
    ];
    const toggleSwitch: IToggleSwitch = {
        content: <>{ translate("fp-list.view") }</>,
        onChange: () => {
            setUseList(!useList);
        },
        state: useList
    };

    return <PageWrapper className={styles.homePageView} pageHeaderTitle={translate("fp-home.title")} headerActionBar={<HeaderActionBar buttons={buttons} toggleswitch={toggleSwitch} />}>
        <div className={styles.content}>
            <ArticleList useList={useList}/>
        </div>
    </PageWrapper>;
};
