import React from "react";
import { Button, Switch } from "antd";
import styles from "./HeaderActionBar.module.css";

export type ButtonPosition = "left" | "right";

export interface IActionButton {
    position: ButtonPosition;
    content: JSX.Element;
    icon?: JSX.Element;
    onClick: () => any;
}

export interface IToggleSwitch {
    content: JSX.Element;
    onChange : () => any;
    state: boolean;
}

interface IProps {
    buttons: IActionButton[];
    toggleswitch?: IToggleSwitch;
}

function toggleSwitchAvailable (switchProp?:IToggleSwitch) {
    if (switchProp != undefined) {
     return <div className={styles.wrapper}>
                <Switch onChange={switchProp.onChange} checked={switchProp.state} className={styles.toggleSwitch}/>
                <div className={styles.toggleSwitch}> { switchProp.content } </div>
            </div>;
    }
}

export const HeaderActionBar = (props: IProps): JSX.Element => {
    const { buttons, toggleswitch } = props;

    const leftButtons = buttons.filter(x => !x.position || x.position === "left");
    const rightButtons = buttons.filter(x => x.position === "right");

    return <div className={styles.headerActionBar}>
        <div className={styles.headerActionBarLeft}>
            { toggleSwitchAvailable(toggleswitch) }
            {leftButtons.map((x, i) => <Button type="primary" key={`button-${i}`} className={styles.actionBarButton} icon={x.icon} onClick={x.onClick}>{x.content}</Button>)}
        </div>
        <div className={styles.headerActionBarRight}>
            {rightButtons.map((x, i) => <Button type="primary" key={`button-${i}`} className={styles.SpecialActionBarButton} icon={x.icon} onClick={x.onClick}>{x.content}</Button>)}
        </div>
    </div >;
};
