import { appDependencies } from "../wiring/AppDependencies";
import { IStateService } from "./IStateService";

export class StateService implements IStateService {
    async set(key: string, value: any): Promise<boolean> {
        return appDependencies.ILocalRepository.store(key, value);
    }

    async get<T>(key: string): Promise<T> {
        return appDependencies.ILocalRepository.get<T>(key);
    }

    delete(key: string) {
        appDependencies.ILocalRepository.delete(key);
    }
}
