import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, InputNumber, Modal } from "antd";
import { tr } from "date-fns/locale";
import React, { useEffect, useReducer, useState } from "react";
import { ArticleRepository } from "../../../adapters/WebApiAdapter/ArticleRepository";
import { LoadingIndicator } from "../../../components/basic/LoadingIndicator/LoadingIndicator";
import { OnlyWhen } from "../../../components/core/DeleteAction";
import { FormInputGroup } from "../../../components/core/FormInputGroup/FormInputGroup";
import { IArticle } from "../../../domain/articles/IArticle";
import { AppRoutes } from "../../../domain/navigators/AppRoutes";
import { appDomain, navigateTo, translate } from "../../../domain/wiring/AppDomain";
import { classNames, delay } from "../../../helpers/tsHelper";
import { IActionButton } from "../../home/headerbar/HeaderActionBar";
import { CustomFileUpload } from "../upload/CustomFileUpload";
import styles from "./ArticleForm.module.css";
import { ArticleGroupSelector } from "./selectors/ArticleGroupSelector";
import { CountrySelector } from "./selectors/CountrySelector";
import { CurrencySelector } from "./selectors/CurrencySelector";
import { UnitSelector } from "./selectors/UnitSelector";

interface IProps {
    article: IArticle;
    setActionButtons: (actionButtons: IActionButton[]) => any;
}

interface IFormValues extends IArticle {

}

export const ArticleForm = (props: IProps): JSX.Element => {
    const { article, setActionButtons } = props;
    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 }
    };

    function articleReducer(state: IFormValues, article: IArticle): IFormValues {
        return {
            ...state,
            ...article
        };
    }

    const [errors, setErrors] = useState<Array<string>>([]);
    const [initialValues, dispatch] = useReducer(articleReducer, article);
    const [form] = Form.useForm<IFormValues>();
    const [loaded, setLoaded] = useState<boolean>(true);
    const [safeOnly, setSafeOnly] = useState<boolean>(false);

    useEffect(() => {
        dispatch(article);
    }, [article]);

    useEffect(() => {
        setActionButtons([
            {
                position: "left",
                content: <>{translate("cancel")}</>,
                icon: <UndoOutlined />,
                onClick: () => navigateTo(AppRoutes.home)
            },
            {
                position: "right",
                content: <>{translate("saveExit")}</>,
                icon: <SaveOutlined />,
                onClick: () => form.submit()

            },
            {
                position: "right",
                content: <>{translate("save")}</>,
                icon: <SaveOutlined />,
                onClick: () => SafeOnlyFromButton()
            }
        ]);
    }, [setActionButtons, form]);

    if (!loaded) return <LoadingIndicator isLoading={true} label="saving" />;

    const onFinish = async (values: IFormValues) => {
        setErrors([]);
        setLoaded(false);

        var result = await onSafeSubmit(values);
        if (result != null) {
            if (!result.success) {
                setErrors(result.validationResults.map(x => translate(x.message)));
            }
        }

        if (safeOnly) {
            setSafeOnly(false);
        } else {
            navigateTo(AppRoutes.home);
        }
        setLoaded(true);
    };

    const SafeOnlyFromButton = () => {
        setSafeOnly(true);
        form.submit();
    };

    const onSafeSubmit = async (values: IFormValues) => {
        if (values.decimals == null) { values.decimals = 0; }
        setLoaded(false);
        const newArticle = {
            ...article,
            ...values,
            rowId: article.rowId
        };
        let result = null;

        try {
            result = await appDomain.IArticleService.createOrUpdateArticle(newArticle);
            showModel(translate("dataSaved"), true);
            setLoaded(true);
            return result;
        } catch (e) {

        }
        return result;
    };

    function showModel(text: string, success: boolean) {
        if (success) {
            return Modal.success({
                title: text
            });
        } else {
            return Modal.error({
                title: text
            });
        }
    }

    return <div>
        <Form form={form}
            {...layout}
            name="basic"
            initialValues={initialValues}
            onFinish={onFinish}
            className={styles.articleForm}
        >
            <FormInputGroup title={translate("general")}>
                <Form.Item
                    labelAlign="left"
                    label={translate("itemNumber")}
                    name="number"
                    rules={[{ required: true, message: translate("itemNumber") }]}
                >
                    <Input className={styles.numberInput} />
                </Form.Item>

                <Form.Item
                    labelAlign="left"
                    label={translate("item")}
                    name="name"
                    rules={[{ required: true, message: translate("item") }]}
                >
                    <Input className={styles.nameInput} />
                </Form.Item>

                <Form.Item
                    labelAlign="left"
                    label={translate("eaNnumber")}
                    name="ean"
                    rules={[{ required: true, message: translate("eaNnumber") }]}
                >
                    <Input className={styles.nameInput} />
                </Form.Item>

                <Form.Item valuePropName="checked"
                    labelAlign="left"
                    label={translate("blocked")}
                    name="blocked"
                >
                    <Checkbox />
                </Form.Item>
            </FormInputGroup>
            <FormInputGroup title={translate("price")}>

                <Form.Item
                    labelAlign="left"
                    label={translate("salesPrice")}
                    name="salesPrice"
                    rules={[{ required: true, message: translate("currency") }]}
                >
                    <InputNumber {...classNames(styles.numberInput, styles.salesPrice)} />
                </Form.Item>

                <Form.Item
                    labelAlign="left"
                    label={translate("currency")}
                    name="salesPriceCurrency"
                    rules={[{ required: true, message: translate("currency") }]}
                >
                    <CurrencySelector />
                </Form.Item>

            </FormInputGroup>

            <FormInputGroup title={translate("group")}>
                <Form.Item
                    labelAlign="left"
                    label={translate("inventoryGroup")}
                    name="articleGroup"
                    rules={[{ required: true, message: translate("inventoryGroup") }]}
                >
                    <ArticleGroupSelector />
                </Form.Item>

                <Form.Item
                    labelAlign="left"
                    label={translate("unit")}
                    name="unit"
                    rules={[{ required: true, message: translate("unit") }]}
                >
                    <UnitSelector />
                </Form.Item>

                <Form.Item
                    labelAlign="left"
                    label={translate("decimals")}
                    name="decimals"
                >
                    <InputNumber {...classNames(styles.numberInput, styles.decimals)} />
                </Form.Item>
            </FormInputGroup>

            <FormInputGroup title={translate("eUsales")}>
                <Form.Item
                    labelAlign="left"
                    label={translate("tariffNumber")}
                    name="tariffNumber"
                    rules={[{ required: false, message: translate("tariffNumber.mand") }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    labelAlign="left"
                    label={translate("countryOfOrigin")}
                    name="countryOfOrigin"
                    rules={[{ required: false, message: translate("countryOfOrigin") }]}
                >
                    <CountrySelector />
                </Form.Item>

            </FormInputGroup>
            <FormInputGroup title={translate("fp-article.fieldset.images")} className={styles.largeColumnWidth}>
                {OnlyWhen(!!article.rowId, () => <CustomFileUpload attachmentKeys={article.attachmentKeys} articleId={article.rowId} />)}
                {OnlyWhen(!article.rowId, () => <div className={styles.saveFirstMessage}>{translate("fp-article.save.first")}</div>)}

            </FormInputGroup>
        </Form>
        <div className={styles.validationResults}>
            {errors.map(x => <div key={x}>{x}</div>)}
        </div>
    </div>;
};
